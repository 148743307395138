import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Section from "../../../containers/Section";
import FeaturesCard from "../../FeaturesCard";
import { PAGE } from "../../../constants/pages";

interface AdsManagementProps {
  title: string;
  features: any;
  page: string;
}

const AdsManagement: React.FC<AdsManagementProps> = props => {
  const {
    title,
    features,
    page
  } = props;


  return (
      <Section className="md:pt-18">
        <div className="w-full mx-auto lg:max-w-4xl text-center">
          <Header variant={HeaderVariant.h2}>{title}</Header>
        </div>
        <FeaturesCard headerVariant={HeaderVariant.h3} border={true} backgroundColor="transparent" page={PAGE.AMAZON_PPC} features={features} />
      </Section>
  );
};

export default AdsManagement;
