import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import { StaticImage } from "gatsby-plugin-image";
import RowImageContent from "../../RowImageContent";
import GradientTitle from "../../GradientTitle";

interface AmazonPPCSoftwareProps {
  gradientTitle: string[];
  gradientPosition: number;
  features?: {
    img: typeof StaticImage;
    title: string;
    description: string;
    subtitle: string;
    cta: {
      text: string;
      route: string;
    };
    imgOrder?: string;
    contentClassName?: string;
  }[];
}

const AmazonPPCSoftware: React.FC<AmazonPPCSoftwareProps> = props => {
  const { gradientTitle, gradientPosition, features } = props;

  return (
    <Section className="mt-18">
      <div className="w-full lg:max-w-6xl mx-auto text-center">
        <GradientTitle
          gradientPosition={gradientPosition}
          color="purple"
          variant={HeaderVariant.h2}
          title={gradientTitle}
          lineBreak={true}
        />
      </div>
      <div className="mt-24">
        {features &&
          features.map((feature: any, index: number) => {
            return (
              <RowImageContent
                key={index}
                image={feature.img}
                title={feature.title}
                subtitle={feature.subtitle}
                description={[feature.description]}
                cta={feature.cta}
                headerVariant={HeaderVariant.h4}
                subtitleVariant={HeaderVariant.h3}
                imgOrder={feature.imgOrder}
                contentClassName={feature.contentClassName}
              />
            );
          })}
      </div>
    </Section>
  );
};

export default AmazonPPCSoftware;
