import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import HeroSection from "../components/HeroSection";
import BottomCTA from "../components/BottomCta";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Name, Type, Location, Category } from "../utils/analytics/constants";
import { useSegment } from "../utils/analytics";
import { nav } from "../utils/navigation";
import { PAGE } from "../constants/pages";
import Icon, { IconType } from "../components/Icon";
import AdsManagement from "../components/AmazonPPCPage/AdsManagement";
import FullWidthQuote from "../components/FullWidthQuote";
import AmazonPPCSoftware from "../components/AmazonPPCPage/AmazonPPCSoftware";
import PerpetuaBenchmarker from "../components/AffiliateProgramPage/PerpetuaBenchmarker";

interface AmazonPPCToolProps {
  data: any;
}

const AmazonPPC: React.FC<AmazonPPCToolProps> = ({ data }) => {
  const pageData = data;
  const { t } = useTranslation();
  const { helpers } = useSegment();

  const [currentQuote, setCurrentQuote] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuote(prev => (prev + 1) % quotes.length);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const heroCta = {
    text: t("Request a Demo"),
    route: t("/demo")
  };

  const adsManagement = [
    {
      title: t("Automate PPC Campaigns"),
      text: t(
        "Use our Amazon PPC advertising software tool to save time and achieve a higher return on ad spend (ROAS)."
      ),
      svg: <Icon iconType={IconType.timeGradient} />
    },
    {
      title: t("Analyze Campaign Performance"),
      text: t(
        "Track your Amazon Sponsored Ads performance for any given period, and see exactly which changes positively affected your campaign results."
      ),
      svg: <Icon iconType={IconType.searchGradient} />
    },
    {
      title: t("Optimize results for profit"),
      text: t(
        "Use our PPC software tool to make the right bid, at the right time, on the most likely to convert keywords to meet your target ACOS."
      ),
      svg: <Icon iconType={IconType.salesTraffic} />
    }
  ];

  const quotes = [
    {
      text: t(
        "“Perpetua is an amazing Amazon PPC software. Leveraging auto campaigns in Sponsored Products has made it easy to identify what’s working for us and what could use improvement. This strategy has given us time back to shift some of our focus back to our bigger products.”"
      ),
      author: {
        name: "Prabhleen Sahota, Head of Marketing Keplin Group",
        img: (
          <StaticImage
            src="../images/amazonPPC/keplin.png"
            alt="quote"
            width={64}
            height={64}
          />
        )
      }
    },
    {
      text: t(
        "“Perpetua is very partner oriented. They really work to establish trust, and build a bond with us.”"
      ),
      author: {
        name: "John Shea, CEO and Founder Momentum Commerce",
        img: (
          <StaticImage
            src="../images/amazonPPC/momentum.png"
            alt="quote"
            width={64}
            height={64}
          />
        )
      }
    },
    {
      text: t(
        "“One of Perpetua’s big strengths is allowing brands to look at performance of specific products. As we launch into new channels, it’s important for us to understand which SKUs are in demand or can even succeed on that channel, and with the flexibility of Perpetua’s goals to hone in at a product-level, we can track performance for each product across each channel.”"
      ),
      author: {
        name: "Aaron Mizrahi, Director of Amazon Hero Cosmetics",
        img: (
          <StaticImage
            src="../images/amazonPPC/heroCosmetics.png"
            alt="quote"
            width={64}
            height={64}
          />
        )
      }
    }
  ];

  const softwareFeatures = [
    {
      img: (
        <StaticImage
          src="../images/amazonPPC/keyword-management.png"
          alt="PPC Keyword Management"
          className="w-full order-2 mx-auto lg:mx-0 mt-10"
        />
      ),
      title: t("Keyword Harvesting Tool"),
      subtitle: t("PPC Keyword Management"),
      description: t(
        "Save time and automate your Amazon PPC keyword management. Choose exactly how Perpetua harvests keywords: fully automated, after a set number of conversions, or to be manually approved before added to campaigns."
      ),
      imgOrder: "order-2",
      contentClassName: "lg:pr-10"
    },
    {
      img: (
        <StaticImage
          src="../images/amazonPPC/bid.png"
          alt="Bid Automation"
          className="w-full  mx-auto lg:mx-0 mt-10"
        />
      ),
      title: t("Automate your Keyword Bidding"),
      subtitle: t("Bid Automation"),
      description: t(
        "Set fixed bids or bid multipliers on strategic targets to push Perpetua’s ad engine in the direction you need. Our Amazon PPC software tool will do the heavy lifting for you, so you can focus on campaign profitability and get the most out of your Amazon ad spend."
      ),
      imgOrder: "order-2 lg:order-1",
      contentClassName: "lg:pl-10"
    },
    {
      img: (
        <StaticImage
          src="../images/amazonPPC/keyword-optimization.png"
          alt="Keyword Optimization"
          className="w-full order-2 mx-auto lg:mx-0 mt-10"
        />
      ),
      title: t("Dominate Share of Voice"),
      subtitle: t("Keyword Optimization"),
      description: t(
        "Easily track and measure your Amazon Share of Voice across search terms, and see how you stack up against the competition. Then leverage Keyword Boosts to launch standalone campaigns on the keywords that matter the most, using Top of Search multipliers to make your ASINs show up exactly where you want them to."
      ),
      imgOrder: "order-2",
      contentClassName: "lg:pr-10"
    },
    {
      img: (
        <StaticImage
          src="../images/amazonPPC/diagram.png"
          alt="Amazon PPC Software"
          className="w-full mx-auto lg:mx-0 mt-10"
        />
      ),
      title: t("Track and Analyze your Amazon PPC Performance"),
      subtitle: t("Amazon PPC Software"),
      description: t(
        "Measure the true impact of your advertising spend with Perpetua’s customizable, enterprise-level reporting that allows you to understand, at a single glance, how your business and products are performing in your category."
      ),
      imgOrder: "order-2 lg:order-1",
      contentClassName: "lg:pl-10"
    }
  ];

  const perpetuaBenchmarker = {
    title: t("The Perpetua Benchmarker"),
    description: [
      t(
        "Benchmarker is the first and only report that will tell your audience how they can master Amazon PPC."
      ),
      t(
        "As an affiliate you will earn a commission for every report that will be generated. The crazy thing? The report is not only incredibly insightful – it’s also free!"
      ),
      t(
        "Simply add an affiliate link or banner to your website, blog, video review or email newsletter — it’s that easy."
      )
    ],
    cta: {
      text: t("Check Our Demo Report"),
      route: t("/amazon-advertising-benchmark-tool/")
    }
  };

  return (
    <Layout>
      <SEO
        title={t("Amazon PPC Software: Sponsored Ads Management tool")}
        description={t(
          " Perpetua's Amazon PPC Software for Sponsored Ads Management & Automation. Save time with our Amazon PPC automation tool and get the most out of your advertising spend."
        )}
        image={`https://perpetua.io${pageData?.openGraphImage?.publicURL}`}
      />

      <HeroSection
        bgGradient="white"
        page={PAGE.AMAZON_PPC}
        titleClassName="md:max-w-2xl"
        gradientTitle={[
          t("Software to"),
          t("maximize"),
          t("your Amazon PPC sales")
        ]}
        gradientPosition={2}
        h1Title={t("Amazon PPC Tool")}
        icon={IconType.amazon}
        description={t(
          "Easily create, automate, and optimize your Amazon PPC ads to drive profitable growth. From automated keyword discovery & migration to automated bidding — get the most powerful PPC automation software tool on the market to help you save time while increasing your Amazon Advertising revenue."
        )}
        cta={heroCta}
        imageReplacement={
          <StaticImage
            src="../images/amazonPPC/hero-img.png"
            alt=""
            className="w-full hidden md:block"
          />
        }
      />

      <AdsManagement
        title={t("Amazon Ads Management")}
        features={adsManagement}
        page={PAGE.AMAZON_PPC}
      />

      <FullWidthQuote
        textColor="text-indigo-3-dark"
        quote={quotes[currentQuote]}
      />

      <AmazonPPCSoftware
        gradientTitle={[
          t("Amazon PPC Software for"),
          t("Campaign Management and Automation")
        ]}
        gradientPosition={2}
        features={softwareFeatures}
      />

      <PerpetuaBenchmarker
        title={perpetuaBenchmarker.title}
        description={perpetuaBenchmarker.description}
        cta={perpetuaBenchmarker.cta}
      />

      <BottomCTA
        className="mt-20"
        title={t("Getting started is easy")}
        subtitle={t(
          "Everything you need to grow your e-commerce business, in one platform."
        )}
        primaryButtonText={t("Get Started")}
        primaryButtonOnClick={() =>
          helpers
            .delayTrack(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: "Get Started",
              page_url: window?.location.href,
              destination: t("/demo/"),
              location: Location.BottomCta
            })
            .then(() => {
              nav(t("/demo/"));
            })
        }
        secondaryButtonText={t("Request a Demo")}
        secondaryButtonOnClick={() =>
          helpers
            .delayTrack(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: t("Request a Demo"),
              page_url: window?.location.href,
              destination: t("/demo/"),
              location: Location.BottomCta
            })
            .then(() => {
              nav(t("/demo/"));
            })
        }
      />
    </Layout>
  );
};

export default AmazonPPC;

export const data = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    openGraphImage: file(relativePath: { eq: "amazonPPC/ogImage.png" }) {
      publicURL
    }
  }
`;
